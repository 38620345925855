<template>
  <div class="px-5">
    <div class="hidden-sm-and-down">
      <v-row>
        <v-col cols="5">
          <div class="text-h4 primary--text">รายงานกำไรขาดทุน</div>
        </v-col>
        <v-col cols="5"></v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="ml-5 px-10"
            @click="downloadExcel()"
          >
            ดาวน์โหลด
            <v-icon right small>mdi-download</v-icon></v-btn
          >
        </v-col>
      </v-row>

      <v-toolbar dense flat rounded outlined class="my-5 pt-3 pb-15">
        <v-row>
          <v-col cols="2">
            <v-select
              label="ช่วงเวลา"
              v-model="timeRangeType"
              :items="timeRangeTypes"
              outlined
              rounded
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="2">
            <DatePicker
              v-if="timeRangeType == 1"
              label="วันเริ่มต้น"
              :value="filter.start_date"
              @onChange="(val) => (filter.start_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
            <v-menu
              v-else-if="timeRangeType == 2"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="filter.start_date ? getMonth(filter.start_date) : ''"
                  label="เดือน"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  hideDetails
                  outlined
                  rounded
                  dense
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="filter.month"
                type="month"
                no-title
                scrollable
                locale="th"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(filter.month), changeMonth()"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="2">
            <DatePicker
              v-if="timeRangeType == 1"
              label="ถึงวันที่"
              :value="filter.end_date"
              @onChange="(val) => (filter.end_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
          </v-col>
          <v-col cols="4"></v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              small
              class="float-right"
              @click="getReportData()"
            >
              แสดงรายงาน <v-icon right>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
<v-row justify="center">
      <v-expansion-panels accordion multiple v-model="panels" style="width: 80%;" >
        <v-expansion-panel>
          <v-expansion-panel-header color="#ededed" class="black--text text-h6"
            >รายได้</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div v-for="(value, key) in report.profit" :key="value.id">
              <template v-if="value.length > 0">
                <v-row justify="center" dense class="py-2 grey--text">
                  <v-col cols="7">
                    {{ key == 'PC1' ? 'พรบ.' : '' }}
                    {{ key == 'PC2' ? 'ประกันภัย' : '' }}
                    {{ key == 'PC3' ? 'ขนส่ง' : '' }}
                    {{ key == 'PC4' ? 'ทั่วไป' : '' }}
                  </v-col>
                </v-row>
                <template v-if="key != 'total_price'">
                  <div
                    v-for="category_data in report.profit[key]"
                    :key="category_data.sub_category_name"
                  >
                    <v-row justify="center" dense class="py-1 grey--text">
                      <v-col cols="3"
                        >{{ category_data.sub_category_name }}
                        {{ category_data.broker }}</v-col
                      >
                      <v-col cols="3" class="text-right">{{
                        category_data.price
                      }}</v-col>
                    </v-row>
                  </div>
                </template>
              </template>
            </div>
            <v-row justify="center" dense class="py-2 grey--text">
              <v-col cols="3" class="primary--text">รายได้รวม</v-col>
              <v-col cols="3" class="primary--text text-right">{{
                report.profit.total_price
              }}</v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="#ededed" class="black--text text-h6"
            >ค่าใช้จ่าย</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div v-for="value in report.loss" :key="value.id">
              <template v-if="value.length > 0">
                <div v-for="data in value" :key="data.id">
                  <v-row justify="center" dense class="pt-1 grey--text">
                    <v-col cols="3">{{ data.sub_category_name }}</v-col>
                    <v-col cols="3" class="text-right">{{ data.price }}</v-col>
                  </v-row>
                </div>
              </template>
            </div>
            <!-- <v-row justify="center" dense class="py-2 grey--text">
            <v-col cols="10">เงินเดือน</v-col>
            <v-col cols="4" offset="1">ค่าใข้จ่ายเงินเดือน และพนักงานรวม</v-col>
            <v-col cols="5" class="text-right">500</v-col>
          </v-row> -->

            <v-row justify="center" dense class="pb-2 grey--text fixed-top">
              <v-col cols="3" class="primary--text">รวมค่าใช้จ่าย</v-col>
              <v-col cols="3" class="primary--text text-right">{{
                report.loss.total_price
              }}</v-col>
            </v-row>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-row justify="center" dense class="py-3 grey--text">
              <v-col cols="3" class="total-color text-h6"
                >กำไร (ขาดทุน) สุทธิ</v-col
              >
              <v-col cols="3" class="total-color text-h6 text-right">{{
                report.difference
              }}</v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      </v-row>
    </div>
    <div class="hidden-md-and-up">
      <v-row>
        <div class="text-h6 primary--text">รายงานกำไรขาดทุน</div>
      </v-row>
      <v-row justify="end" class="mb-3">
        <v-btn color="success" small outlined @click="downloadExcel()">
          ดาวน์โหลด
          <v-icon right small>mdi-download</v-icon></v-btn
        >
      </v-row>
      <v-card outlined class="mb-5">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                label="ช่วงเวลา"
                v-model="timeRangeType"
                :items="timeRangeTypes"
                outlined
                rounded
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-if="timeRangeType == 2"
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="filter.month"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="
                      filter.month
                        ? parseInt(filter.month.substring(0, 4)) +
                          543 +
                          '-' +
                          filter.month.slice(-2)
                        : ''
                    "
                    label="เดือน"
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    hideDetails
                    outlined
                    rounded
                    dense
                    readonly
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.month"
                  type="month"
                  no-title
                  scrollable
                  locale="th"
                  @change="$refs.menu2.save(filter.month), changeMonth()"
                >
                </v-date-picker>
              </v-menu> </v-col
          ></v-row>
          <v-row v-if="timeRangeType == 1">
            <v-col cols="6">
              <DatePicker
                label="วันจดทะเบียน"
                :value="filter.start_date"
                @onChange="(val) => (filter.start_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col cols="6">
              <DatePicker
                label="ถึงวันที่"
                :value="filter.end_date"
                @onChange="(val) => (filter.end_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-3">
              <v-btn
                color="primary"
                small
                class="float-right"
                @click="getReportData()"
              >
                แสดงรายงาน <v-icon right>mdi-magnify</v-icon>
              </v-btn></v-col
            >
          </v-row>
        </v-card-text>
      </v-card>
      <v-expansion-panels accordion multiple v-model="panels">
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#ededed"
            class="black--text text-subtitle-1"
          >
            รายได้
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-subtitle-2">
            <div v-for="(value, key) in report.profit" :key="value.id">
              <template v-if="value.length > 0">
                <v-row justify="center" dense class="grey--text">
                  <v-col cols="10">
                    {{ key == 'PC1' ? 'พรบ.' : '' }}
                    {{ key == 'PC2' ? 'ประกันภัย' : '' }}
                    {{ key == 'PC3' ? 'ขนส่ง' : '' }}
                    {{ key == 'PC4' ? 'ทั่วไป' : '' }}
                    {{ key == 'PC5' ? 'ค่าใช้จ่ายบริษัท' : '' }}
                  </v-col>
                </v-row>
                <template v-if="key != 'total_price'">
                  <div
                    v-for="category_data in report.profit[key]"
                    :key="category_data.sub_category_name"
                  >
                    <v-row justify="center" dense class="py-1 grey--text">
                      <v-col cols="5" offset="2"
                        >{{ category_data.sub_category_name }}
                        {{ category_data.broker }}</v-col
                      >
                      <v-col cols="5" class="text-right">
                      {{ category_data.price }}
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </template>
            </div>
            <v-row justify="center" dense class="py-2 grey--text">
              <v-col cols="5" class="primary--text">รายได้รวม</v-col>
              <v-col cols="5" class="primary--text text-right">
              {{ report.profit.total_price }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            color="#ededed"
            class="black--text text-subtitle-1"
            >ค่าใช้จ่าย</v-expansion-panel-header
          >
          <v-expansion-panel-content class="text-subtitle-2">
            <div v-for="value in report.loss" :key="value.name">
              <template v-if="value.length > 0">
                <div v-for="data in value" :key="data.id">
                  <v-row justify="center" dense class="pt-1 grey--text">
                    <v-col cols="5">{{ data.sub_category_name }}</v-col>
                    <v-col cols="5" class="text-right">{{ data.price }}</v-col>
                  </v-row>
                </div>
              </template>
            </div>

            <v-row style="margin-top:-80px;" justify="center" dense class="py-2 grey--text fixed-top">
              <v-col cols="5" class="primary--text">รวมค่าใช้จ่าย</v-col>
              <v-col cols="5" class="primary--text text-right">
              {{ report.loss.total_price }}
              </v-col>
            </v-row>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-row justify="center" dense class="py-3 grey--text">
              <v-col cols="5" class="total-color text-subtitle-1"
                >กำไร (ขาดทุน) สุทธิ</v-col
              >
              <v-col cols="5" class="total-color text-subtitle-1 text-right">{{
                report.difference
              }}</v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';

import * as moment from 'moment';

export default {
  components: {
    DatePicker,
  },

  data: () => ({
    filter: {
      start_date: moment()
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss'),
      end_date: moment()
        .endOf('month')
        .set({ hour: 23, minute: 59, second: 59 })
        .format('YYYY-MM-DDTHH:mm:ss'),
      month: '',
    },

    search: '',
    selected: [],
    loading: true,
    report: {
      difference: 0,
      document_loss: {
        total_price: 0,
      },
      loss: {
        total_price: 0,
      },
      profit: {
        total_price: 0,
      },
    },
    panels: [0, 1],
    menu: null,
    menu2: null,
    timeRangeType: 0,
    timeRangeTypes: [
      { value: 3, text: 'วันนี้' },
      { value: 0, text: 'เดือนปัจจุบัน' },
      { value: 2, text: 'เดือนก่อน' },
      { value: 1, text: 'เลือกช่วงวันที่' },
    ],
    main_categories: [
      { category_id: 'PC1', name: 'พรบ.' },
      { category_id: 'PC2', name: 'ประกันภัย' },
      { category_id: 'PC3', name: 'ขนส่ง' },
      { category_id: 'PC4', name: 'ทั่วไป' },
      { category_id: 'PC5', name: 'ค่าใช้จ่ายบริษัท' },
    ],
  }),
  created() {
    this.getReportData();
  },
  methods: {
    getMonth(date) {
      return moment(date).format('YYYY-MM');
    },
    changeMonth() {
      console.log(this.filter.month);
      this.filter.start_date = moment(this.filter.month, 'YYYY-MM')
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss');
      this.filter.end_date = moment(this.filter.month, 'YYYY-MM')
        .endOf('month')
        .set({ hour: 23, minute: 59, second: 59 })
        .format('YYYY-MM-DDTHH:mm:ss');
    },
    async getReportData() {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/report/report_profit_and_loss`, body)
        .then(async (res) => {
          this.report = res.result;
          this.report.profit.total_price = (this.report.profit.total_price - this.report.document_loss.total_price)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          this.report.difference = this.report.difference
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          this.report.loss.total_price = this.report.loss.total_price
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        });
      for (
        let index = 0;
        index < this.report.loss.sub_category_array.length;
        index++
      ) {
        this.report.loss.sub_category_array[index].price =
          this.report.loss.sub_category_array[index].price
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      for (let index = 0; index < this.report.profit.PC1.length; index++) {
        this.report.profit.PC1[index].price = this.report.profit.PC1[
          index
        ].price
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      for (let index = 0; index < this.report.profit.PC2.length; index++) {
        this.report.profit.PC2[index].price = this.report.profit.PC2[
          index
        ].price
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      for (let index = 0; index < this.report.profit.PC3.length; index++) {
        this.report.profit.PC3[index].price = this.report.profit.PC3[
          index
        ].price
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      for (let index = 0; index < this.report.profit.PC4.length; index++) {
        this.report.profit.PC4[index].price = this.report.profit.PC4[
          index
        ].price
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    // API undone
    async downloadExcel() {
      this.loading = true;
      this.filter.end_date = moment(this.filter.end_date)
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      console.log(filter)
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_profit_and_loss`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'รายงานกำไรขาดทุน_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'รายงานกำไรขาดทุน_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
  },
  watch: {
    timeRangeType: function (value, oldValue) {
      if (value == 0) {
        // 0 = เดือนปัจจุบัน
        this.filter.start_date = moment()
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 1) {
        // 1 = เลือกช่วงวันที่
        this.filter.start_date = moment()
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 2) {
        // 2 = เดือนก่อน
        this.filter.start_date = moment()
          .subtract(1, 'months')
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .subtract(1, 'months')
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.month = moment().subtract(1, 'months').format('YYYY-MM');
      } else if (value == 3) {
        // 3 = วันนี้
        this.filter.start_date = moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
      }
    },
  },
};
</script>

<style lang="scss">
.total-color {
  color: rgb(29, 92, 132);
}
</style>
